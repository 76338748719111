import { storeGloveTestsOnWindow, sendGloveABTestEvents } from "./glove-test-handler";
import initGloveEventHandler from "./glove-event-listener";
import { isServerSide } from "/utils/general";
let gloveEnvironmentInitialized = false;
function setupGloveEnvironment(abTests, simulateGloveActiveABTests) {
    if (!isServerSide() && !gloveEnvironmentInitialized) {
        storeGloveTestsOnWindow(abTests, simulateGloveActiveABTests);
        sendGloveABTestEvents(abTests);
        initGloveEventHandler();
        gloveEnvironmentInitialized = true;
    }
    return gloveEnvironmentInitialized;
}
export default setupGloveEnvironment;
