import { sendAbTestEvent } from "/services/new-ab-tests/new-ab-tests-service";
const GLOVE_TEST_PREFIX = "glove_test_";
const isGloveTest = (test)=>{
    var _test_name;
    var _test_name_startsWith;
    return (_test_name_startsWith = test === null || test === void 0 ? void 0 : (_test_name = test.name) === null || _test_name === void 0 ? void 0 : _test_name.startsWith(GLOVE_TEST_PREFIX)) !== null && _test_name_startsWith !== void 0 ? _test_name_startsWith : false;
};
const getGloveTests = (abTests = [])=>abTests.filter(isGloveTest);
const sendGloveABTestEvents = (abTests)=>getGloveTests(abTests).forEach(({ name, variant })=>sendAbTestEvent(name, variant));
const storeGloveTestsOnWindow = (abTests = [], simulateGloveActiveABTests = [])=>{
    const allTests = [
        ...abTests,
        ...simulateGloveActiveABTests
    ]; // include simulated tests
    window.activeGloveTests = getGloveTests(allTests).map(({ name, variant })=>({
            name: name,
            variant: variant
        }));
};
export { sendGloveABTestEvents, storeGloveTestsOnWindow };
