import { trackEvent } from "/services/bigbrain-service";
const Sentry = require("@sentry/nextjs");
const GLOVE_TEST_NAME_PREFIX = "glove_";
const formatGloveEventName = (eventName)=>`${GLOVE_TEST_NAME_PREFIX}${eventName}`;
let isMessageListenerAdded = false;
const initGloveEventHandler = ()=>{
    if (isMessageListenerAdded) return;
    function processGloveEvent(details) {
        const { event_name, data } = details;
        try {
            const formattedEventName = formatGloveEventName(event_name);
            trackEvent(formattedEventName, {
                data
            });
        } catch (error) {
            Sentry.captureException(error, {
                extra: {
                    event_name,
                    data,
                    message: "Error processing Glove event"
                }
            });
        }
    }
    window.addEventListener("message", (event)=>{
        var _event_data;
        if (event.origin !== window.location.origin) {
            Sentry.captureMessage("Untrusted message origin: " + event.origin);
            return;
        }
        if (((_event_data = event.data) === null || _event_data === void 0 ? void 0 : _event_data.type) === "glove_event") {
            processGloveEvent(event.data.details);
        }
    });
    isMessageListenerAdded = true;
};
export default initGloveEventHandler;
